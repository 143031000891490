import React from "react";
import styled from "styled-components";

const PaddedImage = (props) => {
    return (
        <ImageContainer className={props.className} captionWidth={props.captionWidth} float={props.float} width={props.width}>
            <Image src={props.src}/>
            <p>{props.children}</p>
        </ImageContainer>
    )
}

const ImageContainer = styled.div`


    ${props => props.float === `left` ? `padding: 0 15px 0 0px;` : ``};
    ${props => props.float === `right` ? `padding: 0 0px 0 15px;` : ``};
    float: ${props => props.float || `none`} ;
    width: ${props => props.width || `auto`};

    p {
        font-size: 0.9rem;
        color: #747373;
        text-align: center;
        width: ${props => props.captionWidth || `85%`};
        margin: 0 auto 10px auto;
    }

    margin: 0;
`

const Image = styled.img`
    width: 100%;
    margin: 0;
`;

export default PaddedImage;