import React from "react";
import styled from "styled-components";

import {HeadingOne} from "./../Headings";
import Skepta from "./../wrappers/Skepta";
import ArticleMetadataTab from "./ArticleMetadataTab";

import {devices} from "./../devices";

import ClipboardIcon from "./../../images/icons/Clipboard.png";

const PostOutline = ({headerImage, headerImageAlt, title, children, articleMetadata}) => {
    return (
        <React.Fragment>
            <PostTitleContainer>
                <Skepta width={`50%`}>
                <PostHeaderImage src={headerImage} alt={headerImageAlt}/>
                <HeadingOne>{title}</HeadingOne>
                </Skepta>
            </PostTitleContainer>
            <PostBodyContainer>
                <Skepta width={`50%`}>
                <ArticleMetadataFlex>
                    <ArticleMetadataTab authorName={articleMetadata.authorName} date={articleMetadata.date} length={articleMetadata.articleLength}/>
                    <LinkCopyMachine>
                        <span className="icon" role="button" alt="Icon for copy to clipboard button"
                            onClick={() => {navigator.clipboard.writeText(window.location.href)}}
                        />
                        <div className="displayText">Copy Link</div>
                    </LinkCopyMachine>
                </ArticleMetadataFlex>
                {children}
                </Skepta>      
            </PostBodyContainer>
        </React.Fragment>
    )
}

const PostTitleContainer = styled.section`
    width: 100%;
    background-color: #F4F4F0;
    padding: 90px 0 1% 0;
    text-align: center;
    h1 {
        text-align: center;

        font-size: 1.5rem;
        line-height: 2rem;

        @media ${devices.tablet} {
            font-size: 2.3rem;
            line-height: 2.8rem;
        }
    }

    @media ${devices.tablet} {
        padding: 10% 0 1% 0;
    }
`

const PostHeaderImage = styled.img`
    width: 100%;
`

const PostBodyContainer = styled.section`
    width: 100%;
    background-color: #fff;
    padding: 2% 0 2% 0;
    text-align: left;

    a {
        color: #4a4a4a;

        &:hover {
            color: #000;
            
        }
    }

    h2 {
        margin-top: 50px;
        margin-bottom: 10px;
    }

    p.clearFix {
        clear: both;
    }
`

const ArticleMetadataFlex = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: left;
    height: 65px;
    margin-bottom: 40px;
    align-items: center;
`

const LinkCopyMachine = styled.div`

    display: none;

    @media ${devices.tablet} {
        display: inline-block;
    }



    height: 65px;
    width: 65px;
    border-radius: 50%;
    border: 1px solid #ececec;
    vertical-align: top;
    margin: 0;
    position: relative;
    padding: 0;



    span.icon {
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('${ClipboardIcon}');
        background-position: center;
        background-size: 45%;
        background-repeat: no-repeat;
    }

    .displayText {
        display: none;
    }


    &:hover {
        border-color: #2aa474;
        cursor: pointer;

        .displayText {
            position: absolute;
            display: flex;
            top: 0;
            left: 80px;
            height: 65px;
            align-items: center;
            width: 50px;
            text-align: left;
            text-transform: uppercase;
            color: #4a4a4a
        }
    }
`

export default PostOutline